import Logo from './Resources/With_mascot.gif';
import './App.css';

function App() {
  const onClick = () => {
    window.open("https://store.steampowered.com/app/3032520/BLUMA/", "_blank")
  }

  return (
    <div className="app">
      <img className='logo' src={Logo} alt='game-logo' />
      <div className='info-container'>
        <button onClick={onClick} className='store-page-button'>Visit Steam Page</button>
      </div>
    </div>
  );
}

export default App;
