import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXKrAoFbRTrmn9ZEan-U-SoxQst-wESPQ",
  authDomain: "potaco-studio.firebaseapp.com",
  projectId: "potaco-studio",
  storageBucket: "potaco-studio.appspot.com",
  messagingSenderId: "171454193640",
  appId: "1:171454193640:web:b86dedd2e9d1ca942ace5d",
  measurementId: "G-WZ4WQ7FZ46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);